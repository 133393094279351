import React, { useEffect, useState } from "react";
import { connection } from "../helper/connection";
import { toastAlert } from "../helper/toastAlert";
import YezABI from "../ABI/YezABI.json";
import copy from "copy-to-clipboard";
import USDTTokenABI from "../ABI/USDTTokenABI.json";
import config from "../config/config";
import RotateLoader from "react-spinners/RotateLoader";
import { useSelector } from "react-redux";
import { convert } from "../helper/convert";

const override = {
  // display: "block",
  // margin: "0 auto",
  borderColor: "#20403e",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

export default function JoinNow({ address }) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [loading, setLoading] = useState(false);
  const [referral, setReferral] = useState(address);
  const [investAmount, setInvestAmount] = useState(0);

  useEffect(() => {
    if (address) {
      window.$("#joinnow_modal").modal("show");
    }
  }, [address]);

  const handleStakeNow = async () => {
    try {
      setLoading(true);
      var get = await connection();
      if (get && get.web3) {
        var web3 = get.web3;
        var address = get.address;
        console.log(referral, "referral", address);
        if (investAmount == "" || isNaN(investAmount)) {
          setLoading(false);
          toastAlert("error", "Enter valid amount");
          return false;
        }
        if (investAmount < 5) {
          setLoading(false);
          toastAlert("error", "Amount must be greater than 5 USDT");
          return false;
        }
        if (referral == "" || referral == undefined) {
          setLoading(false);
          toastAlert("error", "Enter referral address");
          return false;
        }
        if (!web3.utils.isAddress(referral)) {
          setLoading(false);
          toastAlert("error", "Invalid referral address");
          return false;
        }
        let YezContract = new web3.eth.Contract(YezABI, config.YezContAddr);
        let isUser = await YezContract.methods.users(address).call();
        console.log(isUser, "isUser");
        if (isUser?.isExist) {
          setLoading(false);
          toastAlert("error", "User already exist");
          return false;
        }
        let isReferrer = await YezContract.methods.users(referral).call();
        console.log(isReferrer, "isReferrer");
        if (!isReferrer?.isExist) {
          setLoading(false);
          toastAlert("error", "Referral address not exist");
          return false;
        }
        let bnbBalance = await web3.eth.getBalance(address);
        bnbBalance = web3.utils.fromWei(bnbBalance, "ether");
        let PlanAmount = convert(investAmount * 1e18); //UseState Here
        let USDTContract = new web3.eth.Contract(
          USDTTokenABI,
          config.USDTToken
        );
        const gasLimit = await USDTContract.methods
          .approve(config.YezContAddr, PlanAmount.toString())
          .estimateGas({ from: address });
        const gasFee = gasLimit * (await web3.eth.getGasPrice());
        console.log(gasFee, "gasFee", bnbBalance);
        if (bnbBalance < gasFee / 1e18) {
          setLoading(false);
          toastAlert("error", "Insufficient BNB Balance for Gas Fee");
          return false;
        }

        console.log(PlanAmount, "PlanAmount");

        let USDTToken = await USDTContract.methods
          .balanceOf(address.toString())
          .call();
        USDTToken = USDTToken / 1e18;
        console.log(USDTToken, Number(PlanAmount) / 1e18, "checkBalance");
        if (Number(USDTToken) < Number(PlanAmount) / 1e18) {
          setLoading(false);
          toastAlert("error", "Insufficient Balance (USDT)");
          return false;
        }

        await USDTContract.methods
          .approve(config.YezContAddr, PlanAmount.toString())
          .send({ from: address.toString() })
          .then(async (approveRes) => {
            if (approveRes.status == true && approveRes.transactionHash) {
              let _referrer = referral; //UseState Here
              await YezContract.methods
                .regUser(_referrer, PlanAmount.toString())
                .send({ from: address })
                .then(async (registerRes) => {
                  if (
                    registerRes.status == true &&
                    registerRes.transactionHash
                  ) {
                    toastAlert("success", "Stake Successfully Completed");
                    setTimeout(() => {
                      window.location.href = "/dashboard";
                    }, 2500);
                    return false;
                  } else {
                    setLoading(false);
                    toastAlert("error", "Register failed, try again later");
                    return false;
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  toastAlert("error", "Catch error, try again later");
                  return false;
                });
            } else {
              setLoading(false);
              toastAlert("error", "Approve failed, please try again");
              return false;
            }
          })
          .catch((err) => {
            setLoading(false);
            toastAlert("error", "Catch error, try again later");
            return false;
          });
      } else {
        setLoading(false);
        toastAlert("error", "Connect your wallet");
        return false;
      }
    } catch (err) {
      console.log("errrr: ", err);
      setLoading(false);
    }
  };
  async function copyToClipboard(urll) {
    if (walletConnection.address) {
      copy(urll);
      toastAlert("success", "Copied Successfully");
    } else {
      toastAlert("error", "Please connect your wallet");
    }
  }
  return (
    <div>
      {" "}
      <div
        className="modal fade primary_modal"
        id="joinnow_modal"
        tabIndex={-1}
        aria-labelledby="joinnow_modalModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="joinnow_modalModalLabel">
                Join Now
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-group mb-3">
                <label className="mb-1">Amount</label>
                <input
                  type="number"
                  className="primary_inp form-control"
                  placeholder="Enter Amount"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  onChange={(e) => setInvestAmount(e.target.value)}
                />
                <div className="default_id mt-2">
                  {" "}
                  <p>(Minimum deposit is 5 USDT)</p>
                </div>
              </div>
              <div className="form-group mb-3">
                <label className="mb-1">Referral ID</label>
                <input
                  type="text"
                  className="primary_inp form-control"
                  placeholder="Enter Referral ID"
                  value={referral}
                  onChange={(e) => setReferral(e.target.value)}
                  disabled={address ? true : false}
                />
              </div>
              {address ? (
                <></>
              ) : (
                <div className="default_id">
                  <p>
                    Default ID: <span>{config.adminAddress}</span>
                    <i
                      className="fa-solid fa-copy"
                      onClick={() => copyToClipboard(config.adminAddress)}
                      style={{
                        position: "relative",
                        left: "8px",
                        cursor: "pointer"
                      }}
                    ></i>
                  </p>
                </div>
              )}

              <div className="d-flex justify-content-center">
                <button className="primary_btn" onClick={handleStakeNow}>
                  <span>Join Now</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loadBefore">
          <RotateLoader
            color={"#9dfffa"}
            loading={loading}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </div>
  );
}
