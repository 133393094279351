var key = {};
let server = "demo";
if (server === "demo") {
  key = {
    timeDeley: 4000,
    networkId: 97,
    txLink: "https://testnet.bscscan.com",
    rpcUrl: "https://bsc-testnet-rpc.publicnode.com",
    frontUrl: "https://demoyezbitcoin.pages.dev",
    USDTToken: "0x889D0cfDE86dE60F9521AF5865fFFCB5139b78b9", //0x889D0cfDE86dE60F9521AF5865fFFCB5139b78b9
    YezContAddr: "0x3E7Ad79B3C1185F0465dA7C2ED7470cB4035dCB7",
    YezToken: "0xc6EfD239bBf2DB15990677DA337A8bDA9E96A45f",
    adminAddress: "0x80Aa595c83430288E3A75F4D03bE15b72385420F"
  };
} else if (server === "live") {
  key = {
    timeDeley: 4000,
    networkId: 56,
    txLink: "https://bscscan.com",
    rpcUrl: "https://bsc-rpc.publicnode.com",
    frontUrl: "https://yezbitcoin.com",
    USDTToken: "0x55d398326f99059fF775485246999027B3197955",
    YezContAddr: "0x2c2C1F5F2DaC7c8956Dbf5aC3a746CFD8F221371",
    YezToken: "0x738B81fE484408E539188E7Ea4F849bBEa56576C",
    adminAddress: "0x1548C73eF1E6478fDc036b8e3E646d7FDaFab485"
  };
} else {
  key = {
    timeDeley: 4000,
    networkId: 97,
    txLink: "https://testnet.bscscan.com",
    rpcUrl: "https://bsc-testnet-rpc.publicnode.com",
    frontUrl: "http://localhost:3000",
    USDTToken: "0x333cCdDa1f6aD86cCB5A19Eb0fB2E783Be6DeE8d", //0x333cCdDa1f6aD86cCB5A19Eb0fB2E783Be6DeE8d
    YezToken: "0xc6EfD239bBf2DB15990677DA337A8bDA9E96A45f",
    YezContAddr: "0x0E6f4f5963974c9412fAB91B04bf6C6e40c522A5",
    adminAddress: "0x80Aa595c83430288E3A75F4D03bE15b72385420F"
  };
}
export default key;
